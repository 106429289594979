import React from "react";
const LeftSection = () => {
  return (
    <div className="bg-auth bg-login-img">
      <h1>SERVICES</h1>
      {/* <h3>
        ORGANIZATION WHICH USE ADVANCE ANALYTICS SEE A 60% HIGHER INCREASE IN
        YEAR-ON-YEAR REVENUE THAN THOSE THAT DON'T
      </h3> */}
      <ul>
        <li>Mobile App Development</li>
        <li> Website Development </li>
        <li> Game Development </li>
        <li> Degital Marketing & SEO </li>
        <li> Hosting & Server  Solution  </li>
      </ul>
      <div></div>
    </div>
  );
};

export default LeftSection;
